import { IUser, IUserStatus } from '../features/users/types';
import {
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../utils/types';
import API from './api';

interface IAPIUserList {
    meta: Meta;
    users: IAPIUser[];
}

interface IAPIUsersAvailabilitiesList {
    meta: Meta;
    users: IAPIUsersAvailability[];
}

interface Meta {
    count: number;
    total: number;
    current_page: number;
    per_page: number;
    next_page_link: string | null;
    previous_page_link: string | null;
}

export interface IAPINumber {
    availability_status: string;
    country: string;
    created_at: Date;
    digits: string;
    direct_link: string;
    id: number;
    is_ivr: boolean;
    live_recording_activated: boolean;
    name: string;
    open: boolean;
    priority: null;
    time_zone: string;
}

export interface IAPIUser {
    availability_status: string;
    available: boolean;
    created_at: Date;
    direct_link: string;
    email: string;
    id: number;
    language: string | null;
    name: string;
    numbers: IAPINumber[] | null;
    time_zone: string;
}

interface IAPIUsersAvailability {
    availability: string;
    id: number;
}

export default async function getUsers(): Promise<IUser[]> {
    const res: IUser[] = [];
    let page = 1;
    let stop = false;

    while (stop === false) {
        let url = '/users';

        const queryParams = new URLSearchParams();

        queryParams.set('order', 'desc');
        queryParams.set('per_page', '50');
        queryParams.set('page', page.toString(10));

        url += '?' + queryParams.toString();

        try {
            const { status, body } = await API.getInstance().query('GET', url);

            if (status !== 200 || body.length === 0) {
                throw new Error('Failed getting users');
            }
            const response: IAPIUserList = JSON.parse(body);

            if (isNullOrUndefined(response.meta)) {
                throw new Error('No meta sent in /users');
            }
            if (isNullOrUndefined(response.users)) {
                throw new Error('No users sent in /users');
            }
            if (isNullOrUndefined(response.meta.next_page_link)) {
                stop = true;
            }
            page++;
            res.push(
                ...response.users
                    .map(apiUserToStoreUser)
                    .filter<IUser>((c: IUser | null): c is IUser => !isNullOrUndefined(c)),
            );
        } catch (err) {
            return Promise.reject(err);
        }
    }
    stop = false;
    page = 1;
    while (stop === false) {
        let url = '/users/availabilities';

        const queryParams = new URLSearchParams();

        queryParams.set('per_page', '50');
        queryParams.set('page', page.toString(10));

        url += '?' + queryParams.toString();

        try {
            const { status, body } = await API.getInstance().query('GET', url);

            if (status !== 200 || body.length === 0) {
                throw new Error('Failed getting users avilabilities');
            }
            const response: IAPIUsersAvailabilitiesList = JSON.parse(body);

            if (isNullOrUndefined(response.meta)) {
                throw new Error('No meta sent in /users/availabilities');
            }
            if (isNullOrUndefined(response.users)) {
                throw new Error('No users sent in /users/availabilities');
            }
            if (isNullOrUndefined(response.meta.next_page_link)) {
                stop = true;
            }
            page++;
            response.users.forEach((avail: IAPIUsersAvailability) => {
                let status: IUserStatus = 'offline';

                switch (avail.availability) {
                    case 'after_call_work':
                    case 'available':
                    case 'do_not_disturb':
                    case 'in_call':
                    case 'offline':
                        status = avail.availability;
                        break;
                    default:
                        break;
                }
                res.some((u: IUser) => {
                    if (u.id === avail.id) {
                        u.status = status;
                        return true;
                    }
                });
            });
        } catch (err) {
            return Promise.reject(err);
        }
    }
    return Promise.resolve(res);
}

export function apiUserToStoreUser(user: IAPIUser): IUser | null {
    if (isNullOrUndefined(user) || isNullOrUndefinedOrZero(user.id)) {
        return null;
    }

    let lang: string | null = null;
    if (!isNullOrUndefinedOrEmptyString(user.language)) {
        const parts = user.language.split('-');

        if (parts.length > 1) {
            lang = parts[0].toLocaleLowerCase();
        }
    }

    let number: string | null = null;

    if (!isNullOrUndefinedOrEmptyArray(user.numbers)) {
        user.numbers.some((n: IAPINumber) => {
            if (!isNullOrUndefinedOrEmptyString(n.digits)) {
                number = n.digits;
                return true;
            }
            return false;
        });
    }

    let email = '';
    if (!isNullOrUndefinedOrEmptyString(user.email)) {
        email = user.email;
    }

    return {
        email,
        id: user.id,
        lang,
        name: user.name || 'N/A',
        number,
        status: 'offline',
    };
}
