import API from './api';

export async function transferCallToUser(call: number, user: number): Promise<void> {
    const url = `/calls/${call}/transfers`;

    const data = {
        user_id: user.toString(10),
    };

    try {
        const { status } = await API.getInstance().query('POST', url, data);

        if (status !== 204) {
            throw new Error('Failed transfering call');
        }
    } catch (err) {
        return Promise.reject(err);
    }
    return Promise.resolve();
}

export async function transferCallToExternal(call: number, dest: string): Promise<void> {
    const url = `/calls/${call}/transfers`;

    const data = {
        number: dest,
    };

    try {
        const { status } = await API.getInstance().query('POST', url, data);

        if (status !== 204) {
            throw new Error('Failed transfering call');
        }
        return Promise.resolve();
    } catch (err) {
        return Promise.reject(err);
    }
}
