import React, { useEffect, useState } from 'react';

import { formatDuration } from './utils/time';

interface IProps {
    start: number | null;
}

export const Timer: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const init = props.start === null ? 0 : new Date().getTime() - props.start;
    const [duration, setDuration] = useState<number>(init);

    useEffect(() => {
        const timer = setInterval(() => {
            if (props.start === null) {
                setDuration(0);
                return;
            }
            setDuration(new Date().getTime() - props.start);
        }, 200);
        return () => clearInterval(timer);
    }, []);

    return <span>{duration > 0 ? formatDuration(duration) : ''}</span>;
};

export default Timer;
