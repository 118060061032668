const rateLimit = 120;

export default class API {
    private apiID = '021f6fa55635f935adae58105b79879c';
    private apiToken = '53d4114f4a9675ed7fe88b47c0b1c1f2';
    private apiURL = 'https://api.aircall.io/v1';
    private currentError = '';
    private rateCount = 0;
    private rateTS: number;
    private rateTotalCount = 0;
    private rateTotalTS: number;
    private static instance: API;

    public constructor() {
        this.rateTS = new Date().getTime();
        this.rateTotalTS = new Date().getTime();
        window.setInterval(() => {
            this.rateTimer();
        }, 200);
    }

    public static getInstance(): API {
        if (!API.instance) {
            API.instance = new API();
        }
        return API.instance;
    }

    public async query(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        path: string,
        data?: unknown,
    ): Promise<{ status: number; headers: Headers; body: string }> {
        if (this.currentError !== '') {
            return Promise.reject(new Error(this.currentError));
        }
        if (this.rateTotalCount === 0) {
            this.rateTotalTS = new Date().getTime();
        }
        this.rateCount++;
        this.rateTotalCount++;

        if (path.length === 0 || path[0] != '/') {
            return Promise.reject(new Error('Invalid path'));
        }
        let url = this.apiURL;
        url = url[url.length - 1] === '/' ? url.substring(0, url.length - 1) : url;
        url += path;

        const headers: Headers = new Headers();
        let body: BodyInit | null = null;

        headers.set('authorization', `Basic ${this.getAuthString()}`);

        if (typeof data === 'string') {
            headers.set('content-type', 'text/plain');
            body = data;
        } else if (typeof data === 'object') {
            headers.set('content-type', 'application/json');
            try {
                body = JSON.stringify(data);
            } catch (err) {
                return Promise.reject(err);
            }
        } else if (typeof data !== 'undefined') {
            return Promise.reject(new Error('Invalid data value'));
        }

        const response = await fetch(url, {
            body,
            cache: 'no-cache',
            credentials: 'omit',
            headers,
            method,
            mode: 'cors',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });

        const txt = await response.text();
        return Promise.resolve({ status: response.status, headers: response.headers, body: txt });
    }

    public getCurrentUsage(): number {
        return this.rateCount;
    }

    public getAverage(): number {
        return this.rateTotalCount / ((new Date().getTime() - this.rateTotalTS) / 60000);
    }

    private getAuthString(): string {
        return btoa(`${this.apiID}:${this.apiToken}`);
    }

    private async rateTimer() {
        const now = new Date().getTime();

        if (now - this.rateTS > 60000) {
            this.rateCount = 0;
            this.rateTS = now;
            this.currentError = '';
            return;
        }
        if (this.rateCount >= rateLimit - 1) {
            this.currentError = 'Rate limit exceeded';
        }
    }
}
