import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IIncomingCall, IncomingCallStatus } from './types';
import { RootState } from '../../app/store';

export interface IIncomingState {
    activeCall: number | null;
    calls: IIncomingCall[];
    status: 'normal' | 'loading' | 'failed';
}

const initialState: IIncomingState = {
    activeCall: null,
    calls: [],
    status: 'normal',
};

export const incomingSlice = createSlice({
    name: 'incoming',
    initialState,
    reducers: {
        append: (state, action: PayloadAction<IIncomingCall>) => {
            if (!state.calls.some((call: IIncomingCall) => call.id === action.payload.id)) {
                state.calls = [...state.calls, action.payload];
            }
        },
        remove: (state, action: PayloadAction<number>) => {
            state.calls = state.calls.filter((call: IIncomingCall) => call.id !== action.payload);
        },
        setActiveCall: (state, action: PayloadAction<number | null>) => {
            state.activeCall = action.payload;
        },
        // call_id, status, new time value
        updateStatus: (state, action: PayloadAction<[number, IncomingCallStatus, number | null | undefined]>) => {
            state.calls = state.calls.map((call: IIncomingCall) => {
                if (call.id === action.payload[0]) {
                    // Bug in the sdk, we receive a cancelled event after accepted
                    if (call.status === 'accepted' && action.payload[1] === 'missed') {
                        return call;
                    }
                    return {
                        from: call.from,
                        id: call.id,
                        status: action.payload[1],
                        time:
                            action.payload[2] === null
                                ? null
                                : typeof action.payload[2] === 'undefined'
                                ? call.time
                                : action.payload[2],
                    };
                }
                return call;
            });
        },
    },
});

export const { append, remove, setActiveCall, updateStatus } = incomingSlice.actions;

export const selectActiveCall = (state: RootState): number | null => state.incoming.activeCall;
export const selectCalls = (state: RootState): IIncomingCall[] => state.incoming.calls;

export default incomingSlice.reducer;
