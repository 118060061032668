import React from 'react';

import { selectCalls } from './features/calls/reducer';
import { selectMe } from './features/users/reducer';
import { ICall } from './features/calls/types';
import { useAppSelector } from './app/hooks';
import { isNullOrUndefined } from './utils/types';
import InProgressCall from './InProgressCall';

function InProgress(): JSX.Element {
    const me = useAppSelector(selectMe);

    return (
        <div className='entrants_main appels_en_cours'>
            <div className='appels_top_bg'>
                <h2 className='appels_title'>APPELS EN COURS</h2>
            </div>
            <div className='appels_bottom_bg'>
                <ul className='call_lists'>
                    {useAppSelector(selectCalls)
                        .filter((call: ICall) => call.status === 'inProgress')
                        .filter((call: ICall) => !isNullOrUndefined(me) && me.id === call.toID)
                        .map((call: ICall) => (
                            <InProgressCall call={call} key={`inprogress_${call.id}`} />
                        ))}
                </ul>
            </div>
        </div>
    );
}

export default InProgress;
