import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

import { selectHoveredUser, setHoveredUser } from './features/dnd/reducer';
import { IUser } from './features/users/types';
import getUser from './aircall/get_user';
import { transferCallToUser } from './aircall/transfer';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZero } from './utils/types';
import Flag from './Flag';

interface IProps {
    onStartCall?: (number: string) => void;
    user: IUser;
}

export const User: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { user } = props;
    const ref = useRef(null);
    const hovered = useAppSelector(selectHoveredUser) === user.id;
    const dispatch = useAppDispatch();

    if (user.status === 'available') {
        const [, connectDrop] = useDrop({
            accept: 'currentCall',
            drop: async ({ id: draggedId }: { id: number; type: string }) => {
                try {
                    await transferCallToUser(draggedId, user.id);
                } catch (err) {
                    console.warn(err);
                }
            },
            hover: () => {
                if (!hovered) {
                    dispatch(setHoveredUser(user.id));
                }
            },
        });
        connectDrop(ref);
    }

    const className = classNames({
        conseillers_list: true,
        disponible_color: user.status === 'available',
        enappel_color: user.status === 'in_call',
        indispo_color: user.status === 'after_call_work' || user.status === 'do_not_disturb',
        historique_list: true,
        hover_drop: hovered,
    });

    return (
        <li
            className={className}
            key={`user_${user.id}`}
            onDoubleClick={async (ev: React.MouseEvent<HTMLLIElement>) => {
                ev.preventDefault();
                ev.stopPropagation();
                if (
                    isNullOrUndefinedOrZero(user.id) ||
                    isNullOrUndefined(props.onStartCall) ||
                    user.status !== 'available'
                ) {
                    return false;
                }
                try {
                    const usr = await getUser(user.id);

                    if (!isNullOrUndefinedOrEmptyString(usr.number)) {
                        props.onStartCall(usr.number);
                    }
                } catch (err) {
                    console.warn(err);
                }
            }}
        >
            <div className='conseillers_name' ref={ref}>
                <Flag lang={user.lang} />
                <p>{user.name}</p>
            </div>
            <p className='conseillers_en'>
                {(() => {
                    switch (user.status) {
                        case 'after_call_work':
                            return 'Autre';
                        case 'available':
                            return 'Disponible';
                        case 'do_not_disturb':
                            return 'Ne pas déranger';
                        case 'in_call':
                            return 'En appel';
                        case 'offline':
                            return 'Déconnecté';
                        default:
                            return 'N/A';
                    }
                })()}
            </p>
        </li>
    );
};

export default User;
