import moment from 'moment';

export function formatDuration(ms: number, showMS = false): string {
    const duration = moment.duration(ms);
    let dur =
        ('0' + duration.hours().toString()).slice(-2) +
        ':' +
        ('0' + duration.minutes().toString()).slice(-2) +
        ':' +
        ('0' + duration.seconds().toString()).slice(-2);

    if (showMS) {
        dur += '.' + ('00' + duration.milliseconds().toString()).slice(-3);
    }
    return dur;
}

export function formatDate(ts: number | Date): string {
    let date = new Date();

    if (typeof ts === 'number') {
        date = new Date(ts);
    } else {
        date = ts;
    }
    return moment(date).format('DD/MM/YYYY');
}

export function formatDateHour(ts: number | Date): string {
    let date = new Date();

    if (typeof ts === 'number') {
        date = new Date(ts);
    } else {
        date = ts;
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
}
