import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IUser, IUsersStoreStatus } from './types';
import getUsers from '../../aircall/get_users';
import { RootState } from '../../app/store';

export interface IUserState {
    me: IUser | null;
    myEmail: string;
    users: IUser[];
    status: IUsersStoreStatus;
}

const initialState: IUserState = {
    me: null,
    myEmail: '',
    users: [],
    status: 'loading',
};

export const refresh = createAsyncThunk('users/refresh', async () => {
    return getUsers();
});

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setMyEmail: (state, action: PayloadAction<string>) => {
            state.myEmail = action.payload;
            if (action.payload !== '') {
                state.users.some((u: IUser) => {
                    if (u.email === action.payload) {
                        state.me = u;
                        return true;
                    }
                });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(refresh.pending, (state: IUserState) => {
                state.status = 'loading';
            })
            .addCase(refresh.rejected, (state: IUserState) => {
                state.status = 'failed';
            })
            .addCase(refresh.fulfilled, (state: IUserState, action: PayloadAction<IUser[]>) => {
                state.status = 'normal';
                state.users = action.payload;
                if (state.myEmail !== '') {
                    action.payload.some((u: IUser) => {
                        if (u.email === state.myEmail) {
                            state.me = u;
                            return true;
                        }
                    });
                }
            });
    },
});

export const { setMyEmail } = usersSlice.actions;
export const selectMe = (state: RootState): IUser | null => state.users.me;
export const selectStatus = (state: RootState): IUsersStoreStatus => state.users.status;
export const selectUsers = (state: RootState): IUser[] => state.users.users;

export default usersSlice.reducer;
