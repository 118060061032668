import React from 'react';

import { selectMe, selectUsers } from './features/users/reducer';
import { IUser, IUserStatus } from './features/users/types';
import { useAppSelector } from './app/hooks';
import { slugify } from './utils/strings';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from './utils/types';
import User from './User';

interface IProps {
    filter: string;
    onStartCall?: (number: string) => void;
}

export const Users: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const search = slugify(props.filter);
    const me = useAppSelector(selectMe);
    const users = useAppSelector(selectUsers);
    const alreadyShowUsers: number[] = [];

    const listUsers = (statuses: IUserStatus[]): JSX.Element[] => {
        return users
            .filter(
                (user: IUser) =>
                    !isNullOrUndefinedOrEmptyString(user.lang) &&
                    !isNullOrUndefined(me) &&
                    !isNullOrUndefinedOrEmptyString(me.lang) &&
                    user.lang === me.lang,
            )
            .filter((user: IUser) => statuses.indexOf(user.status) !== -1)
            .filter((user: IUser) => slugify(user.name).indexOf(search) !== -1)
            .filter((user: IUser) => {
                if (alreadyShowUsers.indexOf(user.id) !== -1) {
                    return false;
                }
                alreadyShowUsers.push(user.id);
                return true;
            })
            .sort((a: IUser, b: IUser): number => {
                if (a.name.toLocaleLowerCase() === b.name.toLocaleLowerCase()) {
                    return 0;
                }
                return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
            })
            .map((user: IUser) => <User key={`user_${user.id}`} onStartCall={props.onStartCall} user={user} />);
    };

    return (
        <>
            <ul className='historique_lists'>
                {listUsers(['available'])}
                {listUsers(['in_call'])}
                {listUsers(['offline'])}
                {listUsers(['after_call_work', 'do_not_disturb'])}
            </ul>
        </>
    );
};

export default Users;
