import AircallPhone, * as AP from 'aircall-everywhere';
import classNames from 'classnames';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import History from './History';
import InProgress from './InProgress';
import Incoming from './Incoming';
import Rates from './Rates';
import Users from './Users';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { refresh as refreshCalls, selectStatus as selectCallsStatus } from './features/calls/reducer';
import { setHoveredUser } from './features/dnd/reducer';
import { IncomingCallStatus } from './features/incoming/types';
import {
    append as appendIncoming,
    remove as removeIncoming,
    setActiveCall,
    updateStatus as updateIncoming,
} from './features/incoming/reducer';
import { refresh as refreshUsers, selectStatus as selectUsersStatus, setMyEmail } from './features/users/reducer';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from './utils/types';

import 'flag-icon-css/css/flag-icons.css';
import './App.css';

import PhoneIcon from './images/phone.svg';
import PhoneOffIcon from './images/phone-off.svg';

const showLoading = false;
let sdk: AircallPhone | null = null;
let dispatch: Dispatch<unknown> | null = null;
let lastAnsweredCall = 0;
let shouldNotify = false;

export const App: React.FC = (): React.ReactElement => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [phoneVisible, showPhone] = useState<boolean>(window.innerWidth >= 1680);
    const [rightSearch, setRightSearch] = useState<string>('');
    const callsStatus = useAppSelector(selectCallsStatus);
    const usersStatus = useAppSelector(selectUsersStatus);

    dispatch = useAppDispatch();

    function changeTab(tab: number): void {
        setActiveTab(tab);

        const scroll = document.querySelector('ul.call_lists.tab-pane');
        if (!isNullOrUndefined(scroll)) {
            scroll.scrollTop = 0;
        }
    }

    const ref = useRef(null);

    const [, connectDrop] = useDrop({
        accept: 'currentCall',
        hover: (item, monitor) => {
            if (monitor.isOver({ shallow: true }) && !isNullOrUndefined(dispatch)) {
                dispatch(setHoveredUser(null));
            }
        },
    });
    connectDrop(ref);

    const notify = (from: string): void => {
        if (!shouldNotify) {
            return;
        }

        const img = '/phone_ring.ico';
        const text = `Appel entrand du ${from}`;
        const n = new Notification('RetraitePlus standard', { body: text, icon: img });

        window.setTimeout(() => {
            if (!isNullOrUndefined(n)) {
                n.close();
            }
        }, 6000);
    };

    useEffect(() => {
        if (isNullOrUndefined(sdk)) {
            initSDK(showPhone, notify);
        }
        const timerAPI = window.setInterval(() => {
            if (!isNullOrUndefined(dispatch)) {
                dispatch(refreshCalls());
                dispatch(refreshUsers());
            }
        }, 4000);
        return () => {
            window.clearInterval(timerAPI);
        };
    }, []);

    return (
        <div ref={ref}>
            <Rates />
            <img
                className={classNames({
                    visible: phoneVisible,
                })}
                id='phoneIcon'
                onClick={() => showPhone(!phoneVisible)}
                src={phoneVisible ? PhoneOffIcon : PhoneIcon}
            />
            <div
                className={classNames({
                    visible: phoneVisible,
                })}
                id='phone'
            />
            <div
                className={classNames({
                    visible: phoneVisible,
                })}
                id='notifications'
            >
                <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            Notification.requestPermission()
                                .then((result) => {
                                    if (result === 'granted') {
                                        shouldNotify = true;
                                    } else {
                                        shouldNotify = false;
                                        e.target.checked = false;
                                    }
                                })
                                .catch(() => {
                                    shouldNotify = false;
                                    e.target.checked = false;
                                });
                        } else {
                            shouldNotify = false;
                        }
                    }}
                    type='checkbox'
                />
                Activer les notifications
            </div>
            <section className='appels_entrants'>
                <div className='left_side'>
                    <Incoming />
                    <InProgress />
                </div>
                <div className='right_side'>
                    <div className='entrants_main nav-tabs-outer appels_tabs'>
                        <div className='appels_top_bg'>
                            <ul className='call_tab nav nav-tabs'>
                                <li
                                    className={classNames({
                                        active: activeTab === 0,
                                        'top-tab-list': true,
                                    })}
                                    onClick={() => {
                                        changeTab(0);
                                    }}
                                >
                                    Conseillers
                                    {showLoading && usersStatus === 'loading' ? (
                                        <svg className='spinner' viewBox='0 0 50 50'>
                                            <circle
                                                className='path'
                                                cx='25'
                                                cy='25'
                                                r='20'
                                                fill='none'
                                                strokeWidth='5'
                                            ></circle>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </li>
                                <li
                                    className={classNames({
                                        active: activeTab === 1,
                                        'top-tab-list': true,
                                    })}
                                    onClick={() => {
                                        changeTab(1);
                                    }}
                                >
                                    Historique
                                    {showLoading && callsStatus === 'loading' ? (
                                        <svg className='spinner' viewBox='0 0 50 50'>
                                            <circle
                                                className='path'
                                                cx='25'
                                                cy='25'
                                                r='20'
                                                fill='none'
                                                strokeWidth='5'
                                            ></circle>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            </ul>
                            <div className='appels_bottom_bg tab-content'>
                                <ul className='call_lists tab-pane'>
                                    <li
                                        className={classNames({
                                            active: activeTab === 0,
                                            call_list: true,
                                            hidden: activeTab !== 0,
                                            shown: activeTab === 0,
                                            tab_call: true,
                                        })}
                                    >
                                        <Users
                                            filter={rightSearch}
                                            onStartCall={(phone_number: string) => {
                                                if (isNullOrUndefined(sdk)) {
                                                    return;
                                                }
                                                sdk.send('dial_number', { phone_number }, () => {
                                                    /* */
                                                });
                                            }}
                                        />
                                    </li>
                                    <li
                                        className={classNames({
                                            active: activeTab === 1,
                                            call_list: true,
                                            hidden: activeTab !== 1,
                                            shown: activeTab === 1,
                                            tab_call: true,
                                        })}
                                    >
                                        <History
                                            filter={rightSearch}
                                            onStartCall={(phone_number: string) => {
                                                if (isNullOrUndefined(sdk)) {
                                                    return;
                                                }
                                                sdk.send('dial_number', { phone_number }, () => {
                                                    /* */
                                                });
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <button
                                id='downClick'
                                onClick={() => {
                                    const scroll = document.querySelector('ul.call_lists.tab-pane');
                                    if (!isNullOrUndefined(scroll)) {
                                        scroll.scrollTop += 20;
                                    }
                                }}
                            >
                                <span></span>
                            </button>
                            <div className='search_input'>
                                <div className='search_call_icon'></div>
                                <input
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        setRightSearch(ev.target.value);
                                    }}
                                    type='text'
                                    value={rightSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

function initSDK(showPhone: React.Dispatch<React.SetStateAction<boolean>>, notify: (from: string) => void) {
    if (!isNullOrUndefined(sdk)) {
        return;
    }

    const setIco = (url: string): void => {
        let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");

        if (isNullOrUndefined(link)) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = url;
    };

    sdk = new AircallPhone({
        domToLoadPhone: '#phone',
        onLogin: (d: AP.OnLoginData) => {
            if (isNullOrUndefined(sdk)) {
                return;
            }
            sdk.isLoggedIn((res: boolean) => {
                console.log('login status:', res);
            });
            if (!isNullOrUndefinedOrEmptyString(d.user.email) && !isNullOrUndefined(dispatch)) {
                dispatch(setMyEmail(d.user.email));
            }
        },
        onLogout: () => {
            /* */
        },
    });

    sdk.on('incoming_call', (data: AP.IIncomingCallData) => {
        if (isNullOrUndefined(dispatch)) {
            return;
        }
        showPhone(true);
        setIco('/phone_ring.ico');
        document.title = `Appel du ${data.from}`;
        notify(data.from);
        dispatch(
            appendIncoming({
                from: data.from,
                id: data.call_id,
                status: 'incoming',
                time: null,
            }),
        );
        dispatch(setActiveCall(data.call_id));
    });
    sdk.on('call_end_ringtone', (data: AP.ICallEndRingtoneData) => {
        if (isNullOrUndefined(sdk) || isNullOrUndefined(dispatch)) {
            return;
        }
        let status: IncomingCallStatus = 'ended';
        let resetTime: number | null = null;
        let activeCall: number | null = null;

        document.title = 'Standard';
        setIco('/favicon.png');

        switch (data.answer_status) {
            case 'answered':
                status = 'accepted';
                resetTime = new Date().getTime();
                sdk.send('exit_keyboard', undefined, () => {
                    /* */
                });
                activeCall = data.call_id;
                lastAnsweredCall = data.call_id;
                break;
            case 'cancelled':
                // Bug in the sdk, we receive a cancelled event after accepted
                if (lastAnsweredCall === data.call_id) {
                    activeCall = data.call_id;
                    status = 'accepted';
                } else {
                    status = 'missed';
                }
                break;
            case 'disconnected':
                status = 'missed';
                break;
            case 'refused':
                status = 'rejected';
                break;
            default:
                console.log(data);
                return;
        }
        dispatch(updateIncoming([data.call_id, status, resetTime]));

        window.setTimeout(() => {
            if (!isNullOrUndefined(dispatch)) {
                dispatch(removeIncoming(data.call_id));
                dispatch(setActiveCall(activeCall));
            }
        }, 4000);
    });
    sdk.on('call_ended', (data: AP.ICallEndedData) => {
        document.title = 'Standard';
        setIco('/favicon.png');
        if (!isNullOrUndefined(dispatch)) {
            dispatch(updateIncoming([data.call_id, 'ended', null]));
            dispatch(removeIncoming(data.call_id));
        }
    });
    sdk.on('outgoing_call', (data: AP.IOutgoingCallData) => {
        document.title = 'Standard';
        setIco('/favicon.png');
        if (!isNullOrUndefined(dispatch)) {
            dispatch(setActiveCall(data.call_id));
        }
    });
}

export default App;
