import classNames from 'classnames';
import React from 'react';

import { useAppSelector } from './app/hooks';
import { selectCalls } from './features/calls/reducer';
import { ICall } from './features/calls/types';
import { selectMe } from './features/users/reducer';
import { slugify } from './utils/strings';
import { formatDate, formatDateHour, formatDuration } from './utils/time';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from './utils/types';

interface IProps {
    filter: string;
    onStartCall?: (number: string) => void;
}

export const History: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const search = slugify(props.filter);
    const me = useAppSelector(selectMe);

    return (
        <>
            <ul className='historique_lists'>
                {useAppSelector(selectCalls)
                    .filter((call: ICall) => !isNullOrUndefined(me) && call.toID === me.id)
                    .filter(
                        (call: ICall) =>
                            slugify(call.to).indexOf(search) !== -1 ||
                            slugify(call.transfer || '').indexOf(search) !== -1 ||
                            call.from.replaceAll(/\s/g, '').indexOf(props.filter.replaceAll(/\s/g, '')) !== -1,
                    )
                    .filter((call: ICall) => {
                        if (call.direction !== 'inbound') {
                            return false;
                        }
                        switch (call.status) {
                            case 'ended':
                            case 'missed':
                            case 'transferred':
                                return true;
                            case 'inProgress':
                            case 'initial':
                            default:
                                return false;
                        }
                    })
                    .map((call: ICall) => {
                        const className = classNames({
                            historique_list: true,
                            history_ended: call.status === 'ended',
                            history_missed: call.status === 'missed',
                            history_transferred: call.status === 'transferred',
                        });
                        const numberClassName = classNames({
                            can_call: call.from !== 'Anonyme',
                            historique_number: true,
                        });
                        return (
                            <li className={className} key={`call_history_${call.id}`}>
                                <p
                                    className={numberClassName}
                                    onDoubleClick={(ev: React.MouseEvent<HTMLParagraphElement>) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        if (call.from === 'Anonyme' || isNullOrUndefined(props.onStartCall)) {
                                            return false;
                                        }
                                        props.onStartCall(call.from);
                                    }}
                                >
                                    {call.from}
                                    <span className='historique_data'>
                                        {(() => {
                                            switch (call.status) {
                                                case 'missed':
                                                case 'ended':
                                                    if (!isNullOrUndefinedOrEmptyString(call.to)) {
                                                        return call.to;
                                                    }
                                                    break;
                                                case 'transferred':
                                                    if (!isNullOrUndefinedOrEmptyString(call.transfer)) {
                                                        return call.transfer;
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return '';
                                        })()}
                                    </span>
                                </p>
                                <div className='date_time'>
                                    <p className='time'>
                                        {call.duration ? formatDuration(call.duration) : '---------'}
                                    </p>
                                    <p className='date'>
                                        {call.time
                                            ? window.innerWidth >= 1320
                                                ? formatDateHour(call.time)
                                                : formatDate(call.time)
                                            : '---------'}
                                    </p>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};

export default History;
