import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface IDnDState {
    hoveredUser: number | null;
}

const initialState: IDnDState = {
    hoveredUser: null,
};

export const dndSlice = createSlice({
    name: 'dnd',
    initialState,
    reducers: {
        setHoveredUser: (state, action: PayloadAction<number | null>) => {
            state.hoveredUser = action.payload;
        },
    },
});

export const { setHoveredUser } = dndSlice.actions;

export const selectHoveredUser = (state: RootState): number | null => state.dnd.hoveredUser;

export default dndSlice.reducer;
