import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICall, ICallsStoreStatus } from './types';
import getCalls from '../../aircall/get_calls';
import { RootState } from '../../app/store';

export interface ICallsState {
    calls: ICall[];
    status: ICallsStoreStatus;
}

const initialState: ICallsState = {
    calls: [],
    status: 'loading',
};

export const refresh = createAsyncThunk('calls/refresh', async () => {
    return getCalls();
});

export const callsSlice = createSlice({
    name: 'calls',
    initialState,
    reducers: {
        append: (state, action: PayloadAction<ICall>) => {
            if (!state.calls.some((call: ICall) => call.id === action.payload.id)) {
                state.calls = [...state.calls, action.payload];
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(refresh.pending, (state: ICallsState) => {
                state.status = 'loading';
            })
            .addCase(refresh.rejected, (state: ICallsState) => {
                state.status = 'failed';
            })
            .addCase(refresh.fulfilled, (state: ICallsState, action: PayloadAction<ICall[]>) => {
                state.status = 'normal';
                state.calls = action.payload;
            });
    },
});

export const { append } = callsSlice.actions;

export const selectCalls = (state: RootState): ICall[] => state.calls.calls;
export const selectStatus = (state: RootState): ICallsStoreStatus => state.calls.status;

export default callsSlice.reducer;
