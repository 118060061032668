import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';

import { transferCallToExternal } from './aircall/transfer';
import { ICall } from './features/calls/types';
import { setHoveredUser } from './features/dnd/reducer';
import { selectActiveCall } from './features/incoming/reducer';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Timer from './Timer';

import TransferImage from './images/transfer.svg';

const phoneRegex = /^\+?[\d ]+$/im;

interface IProps {
    call: ICall;
}

const InProgressCall: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { call } = props;
    const ref = useRef(null);
    const [transferExternal, setTransferExternal] = useState<number>(0);
    const [transferExternalError, setTransferExternalError] = useState<string>('');
    const [transferExternalTo, setTransferExternalTo] = useState<string>('');
    const dispatch = useAppDispatch();
    const activeCallID = useAppSelector(selectActiveCall);
    let previewImage: React.ReactElement | '' = '';

    if (call.id === activeCallID) {
        const [{}, connectDrag, preview] = useDrag({
            collect: (monitor) => {
                const result = {
                    handlerId: monitor.getHandlerId(),
                    isDragging: monitor.isDragging(),
                };
                return result;
            },
            end: () => {
                dispatch(setHoveredUser(null));
            },
            item: { id: call.id },
            type: 'currentCall',
        });
        connectDrag(ref);
        previewImage = <DragPreviewImage connect={preview} src={TransferImage} />;
    }

    return (
        <>
            {transferExternal !== 0 ? (
                <>
                    <div
                        className='transfer_external_popup_backdrop'
                        onClick={() => {
                            setTransferExternal(0);
                        }}
                    />
                    <div className='transfer_external_popup entrants_main entrants_top'>
                        <div className='appels_top_bg'>
                            <h2 className='appels_title'>TRANSFERER L&apos;APPEL VERS L&apos;EXTERIEUR</h2>
                        </div>
                        <p>Saisissez le numéro vers lequel transférer l&apos;appel et appuyez sur entrée :</p>
                        <div className='appels_bottom_bg'>
                            <div className='search_input'>
                                <div className='search_call_icon'></div>
                                <input
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        setTransferExternalTo(ev.target.value);
                                        if (ev.target.value.replaceAll(/\s/g, '').length === 0) {
                                            setTransferExternalError('Numéro de téléphone requis');
                                        } else if (!ev.target.value.replaceAll(/\s/g, '').match(phoneRegex)) {
                                            setTransferExternalError('Numéro de téléphone invalide');
                                        } else {
                                            setTransferExternalError('');
                                        }
                                    }}
                                    onKeyDown={async (ev: React.KeyboardEvent<HTMLInputElement>) => {
                                        if (ev.key === 'Enter') {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            if (transferExternalError !== '') {
                                                return;
                                            }
                                            try {
                                                await transferCallToExternal(
                                                    transferExternal,
                                                    transferExternalTo.replaceAll(/\s/g, ''),
                                                );
                                            } catch (err) {
                                                setTransferExternalError((err as Error).message);
                                            }
                                        }
                                    }}
                                    type='text'
                                    value={transferExternalTo}
                                />
                            </div>
                            <span className='transfer_external_popup_error'>{transferExternalError}</span>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
            <li
                className={classNames({
                    call_list: true,
                    current_call: call.id === activeCallID,
                })}
                onDoubleClick={(ev: React.MouseEvent<HTMLLIElement>) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    if (call.id !== activeCallID) {
                        return;
                    }
                    setTransferExternalError('');
                    setTransferExternal(call.id);
                }}
                ref={ref}
            >
                {previewImage}
                <p>{call.from}</p>
                <div className='cours_name_time'>
                    <p>{call.to}</p>
                    <p>{call.time ? <Timer start={call.time} /> : ''}</p>
                </div>
            </li>
        </>
    );
};

export default InProgressCall;
