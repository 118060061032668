import { IUser } from '../features/users/types';
import { isNullOrUndefined } from '../utils/types';
import API from './api';
import { apiUserToStoreUser, IAPIUser } from './get_users';

interface IAPIGetUserResponse {
    user: IAPIUser | null;
}

export default async function getUser(id: number): Promise<IUser> {
    const url = `/users/${id}`;

    try {
        const { status, body } = await API.getInstance().query('GET', url);

        if (status !== 200 || body.length === 0) {
            throw new Error('Failed getting user');
        }

        const response: IAPIGetUserResponse = JSON.parse(body);

        if (isNullOrUndefined(response.user)) {
            throw new Error('Failed getting user');
        }
        const usr = apiUserToStoreUser(response.user);
        if (isNullOrUndefined(usr)) {
            throw new Error('Failed getting user');
        }
        return Promise.resolve(usr);
    } catch (err) {
        return Promise.reject(err);
    }
}
