import moment from 'moment';

import { ICall, ICallStatus, ICallMissedReason } from '../features/calls/types';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZero } from '../utils/types';
import API from './api';

interface IAPICallList {
    meta: Meta;
    calls: IAPICall[];
}

interface IAPICall {
    answered_at: number;
    archived: boolean;
    asset: null;
    assigned_to: null;
    comments: IComment[];
    contact: null;
    cost: string;
    direct_link: string;
    direction: string;
    duration: number;
    ended_at: number;
    id: number;
    missed_call_reason: string | null;
    number: INumber | null;
    raw_digits: string | null;
    recording: null;
    started_at: number;
    status: string;
    tags: Tag[];
    transferred_by: IUser | null;
    transferred_to: IUser | null;
    user: IUser | null;
    voicemail: null;
}

interface IComment {
    id: number;
    content: string;
    posted_at: number;
    posted_by: IUser;
}

interface IUser {
    availability_status: string;
    available: boolean;
    created_at: Date;
    direct_link: string;
    email: string;
    first_name?: string;
    id: number;
    language?: string;
    name?: string;
    time_zone?: string;
}

interface INumber {
    id: number;
    direct_link: string;
    name: string;
    digits: string;
    created_at: Date;
    country: string;
    time_zone: string;
    open: boolean;
    availability_status: string;
    is_ivr: boolean;
    live_recording_activated: boolean;
    priority: null;
    //messages: Messages;
}

interface Tag {
    id: number;
    name: string;
    created_at: number;
    tagged_by: IUser;
}

interface Meta {
    count: number;
    total: number;
    current_page: number;
    per_page: number;
    next_page_link: string | null;
    previous_page_link: string | null;
}

export interface IGetCallParams {
    fromTS: number;
    toTS: number;
}

export default async function getCalls(params?: Partial<IGetCallParams>): Promise<ICall[]> {
    const res: ICall[] = [];
    let page = 1;
    let stop = false;

    while (stop === false) {
        let url = '/calls/search';
        const queryParams = new URLSearchParams();

        queryParams.set('from', moment().startOf('day').unix().toString());
        queryParams.set('direction', 'inbound');
        queryParams.set('order', 'desc');
        queryParams.set('per_page', '50');
        queryParams.set('page', page.toString());

        if (typeof params !== 'undefined') {
            if (!isNullOrUndefinedOrZero(params.fromTS)) {
                queryParams.set('from', params.fromTS.toString(10));
            }
            if (!isNullOrUndefinedOrZero(params.toTS)) {
                queryParams.set('to', params.toTS.toString(10));
            }
        }
        url += '?' + queryParams.toString();

        try {
            const { status, body } = await API.getInstance().query('GET', url);

            if (status !== 200 || body.length === 0) {
                throw new Error('Failed getting calls');
            }

            const response: IAPICallList = JSON.parse(body);

            if (isNullOrUndefined(response.meta)) {
                throw new Error('No meta sent in /calls/search');
            }
            if (isNullOrUndefined(response.calls)) {
                throw new Error('No calls sent in /calls/search');
            }
            if (isNullOrUndefined(response.meta.next_page_link)) {
                stop = true;
            }
            page++;
            res.push(
                ...response.calls
                    .map(apiCallToStoreCall)
                    .filter<ICall>((c: ICall | null): c is ICall => !isNullOrUndefined(c)),
            );
        } catch (err) {
            return Promise.reject(err);
        }
    }
    return Promise.resolve(res);
}

function apiCallToStoreCall(call: IAPICall): ICall | null {
    if (isNullOrUndefined(call) || isNullOrUndefinedOrZero(call.id) || isNullOrUndefinedOrZero(call.started_at)) {
        return null;
    }

    let status: ICallStatus = 'ended';
    let missedReason: ICallMissedReason | null = null;
    switch (call.status) {
        case 'answered':
            status = 'inProgress';
            break;
        case 'done':
            status = 'ended';
            break;
        case 'initial':
            status = 'initial';
            break;
        default:
            console.log(`Call status: "${call.status}"`);
            return null;
    }

    if (!isNullOrUndefinedOrEmptyString(call.missed_call_reason)) {
        status = 'missed';
        switch (call.missed_call_reason) {
            case 'agents_did_not_answer':
            case 'no_available_agent':
            case 'short_abandoned':
                missedReason = call.missed_call_reason;
                break;
            default:
                missedReason = 'unknown';
        }
    }

    let transfer: string | null = null;
    if (!isNullOrUndefined(call.transferred_by) || !isNullOrUndefined(call.transferred_to)) {
        let from = 'N/A';
        let to = 'N/A';

        if (!isNullOrUndefined(call.transferred_by) && !isNullOrUndefinedOrEmptyString(call.transferred_by.name)) {
            from = call.transferred_by.name;
        } else if (
            !isNullOrUndefined(call.transferred_by) &&
            !isNullOrUndefinedOrEmptyString(call.transferred_by.first_name)
        ) {
            from = call.transferred_by.first_name;
        }
        if (!isNullOrUndefined(call.transferred_to) && !isNullOrUndefinedOrEmptyString(call.transferred_to.name)) {
            to = call.transferred_to.name;
        } else if (
            !isNullOrUndefined(call.transferred_to) &&
            !isNullOrUndefinedOrEmptyString(call.transferred_to.first_name)
        ) {
            to = call.transferred_to.first_name;
        }
        transfer = `${from} ⤏ ${to}`;
        status = 'transferred';
    }

    let to = '';
    if (!isNullOrUndefined(call.user) && !isNullOrUndefined(call.user.name)) {
        to = call.user.name;
    } else if (!isNullOrUndefined(call.number) && !isNullOrUndefined(call.number.name)) {
        to = call.number.name;
    }

    let toID = 0;
    if (!isNullOrUndefined(call.user) && !isNullOrUndefined(call.user.id)) {
        toID = call.user.id;
    }

    let toNumber = '';
    if (!isNullOrUndefined(call.number) && !isNullOrUndefined(call.number.digits)) {
        toNumber = call.number.digits;
    }

    let duration = 0;
    if (!isNullOrUndefinedOrZero(call.answered_at) && !isNullOrUndefinedOrZero(call.ended_at)) {
        duration = (call.ended_at - call.answered_at) * 1000;
    }
    return {
        direction: call.direction === 'outbound' ? 'outbound' : 'inbound',
        duration,
        from:
            !isNullOrUndefinedOrEmptyString(call.raw_digits) && call.raw_digits !== 'anonymous'
                ? call.raw_digits
                : 'Anonyme',
        id: call.id,
        missedReason,
        status,
        time: call.started_at * 1000,
        to,
        toID,
        toNumber,
        transfer,
    };
}
