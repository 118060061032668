import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import API from './aircall/api';

export function Rates(): JSX.Element {
    const [shown, setShown] = useState<boolean>(false);
    const [currentRate, setCurrentRate] = useState<number>(0);
    const [averageRate, setAverageRate] = useState<number>(0);

    useEffect(() => {
        const timerRate = window.setInterval(() => {
            const api = API.getInstance();

            setCurrentRate(api.getCurrentUsage());
            setAverageRate(api.getAverage());
        }, 1000);
        const bindKeys = (ev: KeyboardEvent) => {
            if (ev.type === 'keydown' && ev.ctrlKey && ev.shiftKey && ev.key === '9') {
                setShown(!shown);
            }
        };
        document.addEventListener('keydown', bindKeys);

        return () => {
            window.clearInterval(timerRate);
            document.removeEventListener('keydown', bindKeys);
        };
    });

    return (
        <div
            className={classNames({
                rate: true,
                shown,
            })}
        >
            Courant: {currentRate} / 120
            <br />
            Moyenne: {Math.round(averageRate * 100) / 100} / 120
        </div>
    );
}

export default Rates;
