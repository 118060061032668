import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import callsReducer from '../features/calls/reducer';
import dndReducer from '../features/dnd/reducer';
import incomingReducer from '../features/incoming/reducer';
import usersReducer from '../features/users/reducer';

export const store = configureStore({
    reducer: {
        calls: callsReducer,
        dnd: dndReducer,
        incoming: incomingReducer,
        users: usersReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
