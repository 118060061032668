import { isNullOrUndefined } from './types';

export function capitalize(str: string): string {
    return str
        .toLocaleLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toLocaleUpperCase() + s.substring(1))
        .join(' ');
}

export function limitText(str: string, limit: number): string {
    if (str.length > limit) {
        return str.substring(0, limit) + '…';
    }
    return str;
}

export function random(length = 32): string {
    let val = '';

    while (val.length < length) {
        val += Math.random().toString(36).substring(2, 15);
    }
    return val.substring(0, length);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function ensureString(v: any, defaultValue = ''): string {
    if (typeof v === 'string') {
        return v;
    }
    if (isNullOrUndefined(v)) {
        return defaultValue;
    }
    return v.toString();
}

export function getLastChar(s: string): string {
    if (typeof s === 'string' && s.length > 0) {
        return s[s.length - 1];
    }
    return '';
}

export function slugify(str: string, uppercase = true): string {
    str = uppercase ? str.toLocaleUpperCase() : str.toLocaleLowerCase();
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return str
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}
