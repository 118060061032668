/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */

export function isNull(val: any): val is null {
    return val === null;
}

export function isUndefined(val: any): val is undefined {
    return typeof val === 'undefined';
}

export function isZero(val: any): val is 0 {
    return val === 0;
}

export function isEmptyArray(val: any): val is [] {
    return Array.isArray(val) && val.length === 0;
}

export function isEmptyString(val: any): val is '' {
    return val === '';
}

export function isNullOrUndefined(val: any): val is undefined | null {
    return isNull(val) || isUndefined(val);
}

export function isNullOrUndefinedOrZero(val: any): val is undefined | null | 0 {
    return isNull(val) || isUndefined(val) || isZero(val);
}

export function isNullOrUndefinedOrEmptyArray(val: any): val is undefined | null | [] {
    return isNull(val) || isUndefined(val) || isEmptyArray(val);
}

export function isNullOrUndefinedOrEmptyString(val: any): val is undefined | null | '' {
    return isNull(val) || isUndefined(val) || isEmptyString(val);
}
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
