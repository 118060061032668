import React from 'react';
import classNames from 'classnames';

import { useAppSelector } from './app/hooks';
import { selectCalls as selectCalls } from './features/calls/reducer';
import { ICall } from './features/calls/types';
import { selectCalls as selectIncoming, selectActiveCall } from './features/incoming/reducer';
import { IIncomingCall } from './features/incoming/types';
import Timer from './Timer';

const currentNumber = '+33 1 76 35 08 80';

function Incoming(): JSX.Element {
    const activeCall = useAppSelector(selectActiveCall);

    return (
        <>
            <div className='entrants_main entrants_top'>
                <div className='appels_top_bg'>
                    <h2 className='appels_title'>appels entrants</h2>
                </div>
                <div className='appels_bottom_bg'>
                    <ul className='call_lists'>
                        {useAppSelector(selectIncoming).map((call: IIncomingCall) => {
                            const className = classNames({
                                appel: call.status === 'incoming',
                                call_list: true,
                                decroche: call.status === 'accepted',
                                en_attente: call.status === 'waiting',
                                missed: call.status === 'missed',
                                raccroche: call.status === 'ended',
                                rejected: call.status === 'rejected',
                                transfere: call.status === 'transfered',
                            });
                            return (
                                <li className={className} key={`incoming_call_${call.id}`}>
                                    <div className='call_number'>
                                        <p>{call.from}</p>
                                        <p>
                                            {(() => {
                                                switch (call.status) {
                                                    case 'accepted':
                                                        return 'Décroché';
                                                    case 'ended':
                                                        return 'Raccroché';
                                                    case 'incoming':
                                                        return 'APPEL';
                                                    case 'missed':
                                                        return 'Manqué';
                                                    case 'rejected':
                                                        return 'Refusé';
                                                    case 'transfered':
                                                        return 'Transféré';
                                                    case 'waiting':
                                                        return 'En attente';
                                                    default:
                                                        return '';
                                                }
                                            })()}
                                        </p>
                                    </div>
                                    {(() => {
                                        switch (call.status) {
                                            case 'transfered':
                                            case 'accepted':
                                            case 'waiting':
                                                return (
                                                    <div className='call_time'>
                                                        <p>
                                                            <Timer start={call.time} />
                                                        </p>
                                                    </div>
                                                );
                                            default:
                                                return '';
                                        }
                                    })()}
                                </li>
                            );
                        })}
                        {useAppSelector(selectCalls)
                            .filter((call: ICall) => {
                                return (
                                    call.id !== activeCall &&
                                    call.direction === 'inbound' &&
                                    call.toNumber === currentNumber &&
                                    call.status === 'initial'
                                );
                            })
                            .map((call: ICall) => {
                                const className = classNames({
                                    appel: true,
                                    call_list: true,
                                });
                                return (
                                    <li className={className} key={`incoming_call2_${call.id}`}>
                                        <div className='call_number'>
                                            <p>{call.from}</p>
                                            <p>APPEL</p>
                                        </div>
                                        <div className='call_time'>
                                            <p>
                                                <Timer start={call.time} />
                                            </p>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Incoming;
