import { Provider } from 'react-redux';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './app/store';

import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <App />
            </Provider>
        </DndProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

serviceWorker.unregister();
